import './App.css';
import { isMobile, isBrowser} from 'react-device-detect';
import HomeBrowser from './pages/HomeBrowser.js';
import HomeMobile from './pages/HomeMobile.js'
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App font-playfair">
      <Toaster position='top-right' />
      { isBrowser &&
        <HomeBrowser />
      }
      { isMobile &&
        <HomeMobile />
      }
    </div>
  );
}

export default App;
