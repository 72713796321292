import Cookies from "universal-cookie"

export const cookies = {
    _cookie: new Cookies(),
    get(key) {
        return this._cookie.get(key)
    },
    set(key, value) {
        this._cookie.set(key, value, { path: "/" })
    },
}