import Screenshot from "../../assets/book_page_iphone_m.png"

const Section2 = () => (
    <div className="flex flex-col justify-between h-screen items-center">
        <img className="h-33.3" alt="Bibli home page iphone graphic" src={Screenshot} />
        <div className="w-full h-full px-10 flex justify-center items-center">
            <div className="flex flex-col text-left">
                    <div className="flex flex-col text font-bold text-4xl mb-10">
                        <text className="mb-3">Remember</text>
                        <text>what you read</text>
                    </div>
                    <div className="flex flex-col text-2xl mb-8">
                        <text className="mb-1">Log your reading sessions so you never forget a big twist or an interesting idea.</text>
                    </div>
                    <div className="flex flex-col text-2xl">
                        <text className="mb-1">Plus, you'll never have to use a bookmark again.</text>
                    </div>
            </div>
        </div>
    </div>
)

export default Section2

