import Screenshot from "../../assets/home_iphone.png"
import EmailInput from "../EmailInput"
import Button from "../Button"
import { useState } from "react"
import { postRequest } from "../../utils/api"
import toast from "react-hot-toast"

const Section1 = () => {
    const [email, setEmail] = useState('');

    const saveEmail = async () => {
        toast.promise(postRequest(`/email/save`, {
            email
        }), {
            loading: 'Saving...',
            success: 'Email saved!',
            error: 'Uh oh, something went wrong.',
        }, {
            style: {
                fontWeight: 'bold'
            }
        })
    }

    return (
        <div className="flex flex-row justify-between h-5/6vh px-20 items-center">
            <div className="text-left">
                <div className="flex flex-col text font-bold text-6xl mb-10">
                    <text className="mb-3">A bookworm's</text>
                    <text>best friend</text>
                </div>
                <div className="flex flex-col text-3xl mb-10">
                    <text className="mb-1">Track the books you're reading</text>
                    <text className="mb-1">Save the books you want to read</text>
                    <text>Remember the books you've read</text>
                </div>
                <div className="flex justify-between">
                    <EmailInput className="grow mr-5" change={(txt) => setEmail(txt)}/>
                    <Button label="update me" click={saveEmail}/>
                </div>
            </div>
            <img className="h-5/6 mr-10" alt="Bibli home page iphone graphic" src={Screenshot} />
        </div>
    )
}

export default Section1