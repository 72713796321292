import Screenshot from "../../assets/book_page_iphone.png"

const Section2 = () => (
    <div className="flex flex-row justify-between h-5/6vh px-20 items-center">
        <img className="h-5/6 ml-10" alt="Bibli home page iphone graphic" src={Screenshot} />
        <div className="w-full flex justify-center">
            <div className="flex flex-col text-left">
                    <div className="flex flex-col text font-bold text-6xl mb-10">
                        <text className="mb-3">Remember</text>
                        <text>what you read</text>
                    </div>
                    <div className="flex flex-col text-3xl mb-8">
                        <text className="mb-1">Log your reading sessions so you</text>
                        <text className="mb-1">never forget a big twist or an</text>
                        <text>interesting idea.</text>
                    </div>
                    <div className="flex flex-col text-3xl">
                        <text className="mb-1">Plus, you'll never have to use a</text>
                        <text className="mb-1">bookmark again.</text>
                    </div>
            </div>
        </div>
    </div>
)

export default Section2