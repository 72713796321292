const Bookclub = () => (
    <div className="flex flex-col h-1/2vh bg-black text-white text-left justify-around px-20">
        <div className="flex flex-col">
            <text className="text-6xl font-bold pb-10">Bookclub loading...</text>
            <div className="flex flex-col mt-b">
                <text className="text-3xl mb-1">Make friends</text>
                <text className="text-3xl mb-1">Join bookclubs</text>
                <text className="text-3xl">Recommend and find new books</text>
            </div>
        </div>
    </div>
)

export default Bookclub