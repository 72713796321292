const Bookclub = () => (
    <div className="flex flex-col h-1/2vh bg-black text-white text-left justify-around px-10">
        <div className="flex flex-col">
            <text className="text-4xl font-bold pb-10">Bookclub loading...</text>
            <div className="flex flex-col text-2xl mt-b">
                <text className="mb-1">Make friends.</text>
                <text className="mb-1">Join bookclubs.</text>
                <text className="">Recommend and find new books.</text>
            </div>
        </div>
    </div>
)

export default Bookclub