import TextArea from "../TextArea.js"
import Button from "../Button.js"
import EmailInput from "../EmailInput.js"
import { useState } from "react"
import { postRequest } from "../../utils/api"
import toast from "react-hot-toast"

const Contact = () => {
    const [message, setMessage] = useState('')
    const [fromEmail, setFromEmail] = useState('')

    const sendEmail = async () => {
        toast.promise(postRequest(`/email/send`, {
            message,
            fromEmail
        }), {
            loading: 'Sending...',
            success: 'Thanks for reaching out!',
            error: 'Uh oh, something went wrong.',
        }, {
            style: {
                fontWeight: 'bold'
            }
        })
    }

    return (
        <div className="h-1/2vh flex justify-between items-center px-20">
            <div className="flex flex-col justify-between items-center h-4/6">
                <text className="block text-6xl font-bold">Got questions?</text>
                <Button label="submit" className="text-3xl w-1/2" click={sendEmail}/>
            </div>
            <div className="flex flex-col w-1/2 h-4/6">
                <TextArea className="h-full mb-3" change={(txt) => setMessage(txt)}/>
                <EmailInput change={(txt) => setFromEmail(txt)}/>
            </div>
        </div>
    )
}

export default Contact