import Header from "../components/Browser/Header.js"
import Section1 from "../components/Browser/Section1.js"
import Section2 from "../components/Browser/Section2.js"
import Bookclub from "../components/Browser/Bookclub.js"
import Contact from "../components/Browser/Contact.js"

const HomeBrowser = () => (
    <>
        <Header />
        <Section1 />
        <Section2 />
        <Bookclub />
        <Contact />
    </>
)

export default HomeBrowser;