import Header from "../components/Mobile/Header.js"
import Section1 from "../components/Mobile/Section1.js"
import Section2 from "../components/Mobile/Section2.js"
import Bookclub from "../components/Mobile/Bookclub.js"
import Contact from "../components/Mobile/Contact.js"

const HomeMobile = () => (
    <>
        <Header />
        <Section1 />
        <Section2 />
        <Bookclub />
        <Contact />
    </>
)

export default HomeMobile;