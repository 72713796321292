import axios from "axios"
import * as rax from "retry-axios"

import config from "./config"
import { cookies } from "./utils"

const apiClient = axios.create({
  baseURL: `${config.api.url}`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
  raxConfig: {
    retry: 3,
    noResponseRetries: 2,
    retryDelay: 100,
    httpMethodsToRetry: ["GET", "HEAD", "OPTIONS"],
    statusCodesToRetry: [
      [100, 199],
      [401, 418],
      [429, 429],
      [500, 599],
    ],
  },
})

apiClient.defaults.raxConfig = {
  instance: apiClient,
}

apiClient.interceptors.request.use(
  (config) => {
    const token = cookies.get("token")
    if (token) {
      config.headers.token = `${token}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

export const apiClientRaxInterceptorId = rax.attach(apiClient)
export default apiClient

export const postRequest = async (
  url,
  body,
  headers
) => {
  const response = await apiClient.post(
    url + '/',
    { data: body },
    {
      headers: {
        ...apiClient.defaults.headers,
        ...headers,
      },
    }
  )

  if (response.status >= 400) {
    throw response.data.errors ?? []
  }
  return response.data.data
}

export const getRequest = async (
  url,
  headers,
  params
) => {
  const response = await apiClient.get(url + '/', {
    headers: {
      ...apiClient.defaults.headers,
      ...headers,
    },
    params: {
      ...apiClient.defaults.params,
      ...params,
    },
  })

  if (response.status >= 400) {
    throw response.data.errors ?? []
  }
  return response.data.data
}

export const putRequest = async (
  url,
  body,
  headers
) => {
  const response = await apiClient.put(
    url,
    { data: body },
    {
      headers: {
        ...apiClient.defaults.headers,
        ...headers,
      },
    }
  )

  if (response.status >= 400) {
    throw response.data.errors ?? []
  }
  return response.data.data
}

// import { toast } from "../toast"

export const uploadImage = async (
  file, userId, token
) => {
  try {
    const { signedUrl, key } = await getS3SignedUrlObjectImageApi({
      userId: userId,
      type: file.type,
      token
    })
    if (signedUrl) {
      console.log(signedUrl)
      const resp = await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      })

      console.log(resp)

      return `${config.aws.bucketUrl}/${key}`
    }
  } catch (e) {
    // toast.error("Error uploading image")
    console.log(e)
  }
  return null
}

export const getS3SignedUrlObjectImageApi = async ({
    userId,
    type,
    token
  }) => {
    const response = await getRequest(
      `/user/${userId}/upload/image`,
      { 'User': token },
      { type }
    )
    console.log(response)
    return { signedUrl: response?.signedUrl, key: response?.key }
  }
