// const lh = 'localhost:8080'
// const nh = '192.168.1.151:8080'
const api = 'emailservice-dot-bibli-333803.uc.r.appspot.com'

export const config = {
    api: {
        url: `https://${api}/api`
    },
}

export default config